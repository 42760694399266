import React from 'react';
import * as Sentry from "@sentry/browser";
import SentryConfig from "@/Helpers/SentryConfig";
import CookieService from "@/Services/CookieService";
import GoogleAnalytics from "@/Services/Analytics/GA4";
import NotificationService from "@/Services/NotificationService";
import SharpstarServiceHandler from "@/Services/Handlers/SharpstarServiceHandler";
import {environment, isLocal} from '@/Helpers/enviroments';
import {checkForAdBlocker} from "@/Helpers/svAdBlocker";
import UrlParams from "@/Helpers/UrlParams";
import {SUBSCRIBE_REQUEST_NUMBER} from "@/constants";
import AdBlocker from "@/React/_UI/AdBlocker/AdBlocker";
import config from "Pages/PWA/aq/games/Snake_Game_style4_push/config";
import Welcome from 'Pages/PWA/aq/games/Snake_Game_style4_push/phases/welcome/Welcome';
import PushPhase from 'Pages/PWA/aq/games/Snake_Game_style4_push/phases/push/PushPhase';
import "./main.scss";

export function disableReactDevTools() {
    // Check if the React Developer Tools global hook exists
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== "object") {
        return;
    }

    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        if (prop === "renderers") {
            // initialise this with an empty `Map`,
            // else it will throw an error in console

            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
        } else {
            // Replace all of its properties with a no-op function or a null value
            // depending on their types

            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
                typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
                    ? () => {}
                    : null;
        }
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            installing: false,
            isInstalled: false,
            deferredPrompt: null,
            gameLink: config.link,
            phase: null,
            btnType: 'install',
            offer_id: null,
            adBlocker: false,
            popUpAdBlocker: false,
            goToGame: false
        };

        if (process.env.NODE_ENV === "production") disableReactDevTools();

        this.abortController = new AbortController();

        // Set refid3.
        this.setRefid3AndRid();

        // Init page services: Sentry, Google Analytics.
        this.initServices();

        // Add beforeInstallPrompt & appInstalled event listeners.
        this.addPwaInstallEventListeners();

        // Send initial display mode to analytics services.
        this.ga.initialDisplayMode();

        // Track display mode changes and send to analytics services.
        this.ga.displayModeChangeTracking();

        this.setPhase = this.setPhase.bind(this);
        this.playOnCLick = this.playOnCLick.bind(this);
        this.continueOnCLick = this.continueOnCLick.bind(this);
        this.promptIfAllowed = this.promptIfAllowed.bind(this);
        this.reportAdBlocker = this.reportAdBlocker.bind(this);
        this.showInstallPrompt = this.showInstallPrompt.bind(this);
        this.setSubscribeRequestCookie = this.setSubscribeRequestCookie.bind(this);
    }

    // Set refid3.
    setRefid3AndRid() {
        this.rid = config.rid;
        this.hash = UrlParams.hash;
        this.refid1 = UrlParams.refid1;
        this.refid2 = UrlParams.refid2;
        this.refid3 = UrlParams.refid3;

        // If exists save it to local storage.
        if (this.refid3 !== '0') {
            localStorage.setItem('refid3', this.refid3);
        }
        // Else if exists in local storage set as refid3.
        else if (localStorage.getItem('refid3')) {
            this.refid3 = localStorage.getItem('refid3');
        }
        if (this.refid2 !== '0') {
            localStorage.setItem('refid2', this.refid2);
        }
        // Else if exists in local storage set as refid2.
        else if (localStorage.getItem('refid2')) {
            this.refid2 = localStorage.getItem('refid2');
        }
        if (this.refid1 !== '0') {
            localStorage.setItem('refid1', this.refid1);
        }
        // Else if exists in local storage set as refid1.
        else if (localStorage.getItem('refid3')) {
            this.refid1 = localStorage.getItem('refid1');
        }
        if (this.hash !== '0') {
            localStorage.setItem('hash', this.hash);
        }
        // Else if exists in local storage set as hash.
        else if (localStorage.getItem('hash')) {
            this.hash = localStorage.getItem('hash');
        }
    }

    /**
     * Init page services: Sentry, Google Analytics.
     */
    initServices() {
        this.sharpstarDataService = new SharpstarServiceHandler(
            config.sharpstarApiUrl,
            config.pwaName,
            config.campaign_id,
            new CookieService(config.cookieDomain),
            this.abortController
        );

        SentryConfig.init(
            true,
            null,
            config.pwaName,
            config.campaign_id,
            null,
            document.location.host,
            environment(),
            this.refid3
        );

        this.ga = new GoogleAnalytics(
            true,
            config.pwaName,
            config.gaTrackId,
            {},
            {
                debug: isLocal()
            }
        );

        if (window.OneSignal !== 'undefined') {
            NotificationService.initOneSignal(config.onesignalAppId, isLocal(), true)
                .then(() => {
                    NotificationService.onSubscriptionChange()
                        .then(async (playerId) => {
                            try {
                                let offer_id;
                                if (this.state.offer_id !== null) {
                                    offer_id = this.state.offer_id;
                                } else {
                                    offer_id = localStorage.getItem('offer_id');
                                }

                                await this.sharpstarDataService.createPushLead(playerId, offer_id, this.rid);
                                this.setPhase();
                            } catch (e) {
                                if (e.name === 'AbortError') {
                                    Sentry.addBreadcrumb({
                                        level: 'info',
                                        message: e.message
                                    });
                                } else {
                                    Sentry.captureException(e);
                                }

                                this.setPhase();
                            }
                        })
                        .catch((e) => {
                            Sentry.captureException(e);
                            this.setPhase();
                        });
                    NotificationService.onPermissionChange()
                        .then((permission) => {
                            this.ga.pushNotificationPermission(permission);
                        })
                        .catch((e) => {
                            Sentry.captureException(e);
                        });
                })
                .catch((e) => {
                    Sentry.captureException(e);
                });
        }
    }

    /**
     * when subscription status changes check whether the user is subscribed to push notifications and show prompt message if not
     * @returns {Promise<void>}
     */
    async promptIfAllowed() {
        this.ga.buttonClicked('Push Accept');

        try {
            const isPushEnabled = await NotificationService.checkSubscription();

            if (isPushEnabled) {
                return;
            }
        } catch (e) {
            Sentry.addBreadcrumb(e);
        }

        NotificationService.showPrompt();
    }

    /**
     * Add badPwaInstallEventListenersBeforeInstallPrompt & appInstalled event listeners.
     */
    addPwaInstallEventListeners() {

        console.log('in addPwaInstallEventListeners');
        // before install prompt event listener.
        window.addEventListener('beforeinstallprompt', (e) => {

            console.log(e, 'prompt exists');
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.setState({
                deferredPrompt: e
            });
        });

        // app installed event listener.
        window.addEventListener('appinstalled', () => {
            this.afterInstall()
                .catch((e) => {
                    Sentry.captureException(e);
                });
        });
    }

    /**
     * After PWA install send analytics, pixel to thrive and check device type for launching PWA.
     */
    async afterInstall() {
        this.ga.pwaInstalled();
        // check if mobile device
        // if mobile: show loader while installing and then replace button to launch PWA.
        // if desktop: launch pwa automatically.
        if (this.isMobile()) {
            this.setState({
                installing: true,
                deferredPrompt: null
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isInstalled: true,
                        installing: false,
                        btnType: 'play'
                    }, () => {
                        this.setInstallCookie();
                    });
                }, 10000);
            });
        } else {
            this.setState({
                deferredPrompt: null,
                phase: 'push'
            }, () => {
                this.setInstallCookie();
                this.setPhase();
            });
        }
    }

    goToGame() {
        this.ga.sendPageView('game');

        document.location = this.state.gameLink + '?refid3=' + this.refid3 + '&rid=' + this.rid;
    }

    /**
     * Show browser install prompt.
     * @returns {Promise<void>}
     */
    async showInstallPrompt() {
        if (this.state.adBlocker) {
            this.setState({ popUpAdBlocker: true });
        } else {
            try {
                // trigger browser install prompt.
                this.state.deferredPrompt.prompt();

                // send install button click to analytics.
                this.ga.buttonClicked('PWA Install Button');

                // save user answer and send to analytics.
                const answer = await this.state.deferredPrompt.userChoice;

                this.ga.pwaInstallPromptAnswer(answer.outcome);
            } catch (e) {
                Sentry.captureException(e);
            }
        }
    }

    continueOnCLick() {
        if (this.isCookieExists('subscribeRequest') !== null) {
            const reqNum = this.isCookieExists('subscribeRequest') + 1;

            this.setSubscribeRequestCookie(reqNum);
        } else {
            this.setSubscribeRequestCookie(1);
        }

        this.ga.buttonClicked('Push Deny');
        this.goToGame();
    }

    /**
     * Check if cookie exists.
     * @param cookieName
     * @returns {null}
     */
    isCookieExists(cookieName) {
        const cookies = document.cookie.split(';');
        const parseCookies = [];
        let myCookie = null;

        cookies.forEach((cookie) => {
            parseCookies.push(cookie.split('='));
        });

        parseCookies.forEach((cookie) => {
            if (cookie[0].trim() === cookieName) {
                myCookie = JSON.parse(cookie[1]);
            }
        });

        return myCookie;
    }

    playOnCLick() {
        this.ga.pwaButtonClicked('PWA', 'Play');
    }

    /**
     * Set install cookie
     */
    setInstallCookie() {
        document.cookie = "isInstalled=true;expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=" + document.domain + ";path=/";
    }

    /**
     * Set subscribe request cookie
     */
    setSubscribeRequestCookie(requestNumber) {
        document.cookie = "subscribeRequest=" + requestNumber + ";expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=" + document.domain + ";path=/";
    }

    /**
     * Check if user is on the PWA display mode or in browser.
     * @returns {boolean}
     */
    isStandAlone() {
        return window.matchMedia('(display-mode: standalone)').matches;
    }

    /**
     * Check if user device is mobile.
     * @returns {boolean}
     */
    isMobile() {
        let isMobile = false; //initiate as false
        // device detection
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
            isMobile = true;
        }
        return isMobile;
    }

    setPhase() {
        if (this.isCookieExists('isInstalled') || this.isStandAlone()) {
            this.setInstallCookie();

            if (window.OneSignal === undefined) {
                this.goToGame();
            } else {
                if (this.isCookieExists('subscribeRequest') > SUBSCRIBE_REQUEST_NUMBER) {
                    this.goToGame();
                } else {
                    NotificationService.checkSubscription()
                        .then((isSubscribe) => {
                            if (isSubscribe) {
                                this.goToGame();
                            } else {
                                this.setState({
                                    phase: 'push',
                                });
                            }
                        });
                }
            }
        } else {
            this.setState({
                phase: 'install'
            });
        }
    }

    async reportAdBlocker() {
        const adBlock = await checkForAdBlocker().then(
                adBlockerValue => this.setState({adBlocker: adBlockerValue}
            ))

        await this.ga.adBlock(adBlock);
    }

    async componentDidMount() {
        await this.reportAdBlocker()

        window.addEventListener('beforeunload', () => {
            Sentry.addBreadcrumb({
                level: 'info',
                message: 'user closes the page'
            });
            this.abortController.abort();
        });

        // register to service worker
        let swPath = config.swPath;

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(swPath)
                .catch((err) => {
                    if (err.name === 'AbortError') {
                        Sentry.addBreadcrumb({
                            level: 'info',
                            message: e.message
                        });
                    } else {
                        Sentry.captureException(err);
                    }
                });
        } else {
            Sentry.captureException(new Error('browser could not find the Service-Worker'));
        }

        this.sharpstarDataService.getOffers(undefined, 0, this.rid)
            .then((res) => {
                this.setState({
                    offer_id: res.offers[0].id
                });

                localStorage.setItem('offer_id', res.offers[0].id);
            })
            .catch((e)=> {
                if (e.name === 'AbortError') {
                    Sentry.addBreadcrumb({
                        level: 'info',
                        message: e.message
                    });
                } else  {
                    Sentry.captureException(e);
                }
            });

        this.setPhase();
        this.ga.pwaLaunched('PWA');
    }

    render() {
        return (
            <>
                {
                    this.state.phase === 'push' &&
                    !this.state.popUpAdBlocker &&
                    this.isCookieExists('subscribeRequest') <= SUBSCRIBE_REQUEST_NUMBER &&
                    <PushPhase
                        lang={config.lang}
                        modalsUrl={config.modalsUrl}
                        onClick={this.promptIfAllowed}
                        continueOnCLick={this.continueOnCLick}
                        gameLink={this.state.gameLink}
                        ga={this.ga}
                    />
                }
                {this.state.phase === 'install' && !this.state.popUpAdBlocker &&
                    <Welcome
                        lang={config.lang}
                        modalsUrl={config.modalsUrl}
                        playClick={this.playOnCLick}
                        playLink={this.state.gameLink}
                        onClick={this.showInstallPrompt}
                        isInstalled={this.state.isInstalled}
                        installing={this.state.installing}
                        btnType={this.state.btnType}
                        ga={this.ga}
                    />
                }
                {this.state.adBlocker && this.state.popUpAdBlocker &&
                    <AdBlocker/>
                }
            </>
        );
    }
}

export default App;
