import React from "react";
import './adBlocker.scss';
import imgTitle from './assets/title.png';
import imgBtnReload from './assets/reload-btn.png';

export default class AdBlocker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'container-adblock container-fluid'}>
                <div className={'sv-ad-blocker'}>
                    <div className={'header'}>
                        <img className={'img-title'} src={imgTitle} width={677} height={157} alt="Title"/>
                    </div>
                    <div className={'body'}>
                        <div className={'bg'}>
                            <div className={'content'}>
                                <div className={'msg-alert'}>
                                    <p>
                                        We detected that you are using an ad blocker, <strong>please turn it off</strong>.
                                    </p>
                                </div>
                                <div className={'img-btn-reload'}>
                                    <img src={imgBtnReload} width={433} height={124} alt='reload' onClick={this.props.playClick}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
