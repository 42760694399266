import React from 'react';
import './btnPlay.scss';
import btnPlayImg from './assets/play-btn.png';
import btnInstallImg from './assets/load-btn.png';
import SpinnerDot from "../../../../../../../../../App/React/_UI/SpinnerDot/SpinnerDot";

export default class BtnPlay extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.installing &&
                    <SpinnerDot color={'#ff9801'}/>
                }
                {!this.props.installing &&
                    <div className={'btn-play-container'}>
                        {this.props.btnType === 'play' &&
                            <div className={'btnInstall'} onClick={this.props.playClick}>
                                <a id={'link'} href={document.location.href} target={'_blank'}>
                                    <img className={'btn-play'} src={btnPlayImg} alt='Play'/>
                                </a>
                            </div>
                        }
                        {this.props.btnType === 'install' &&
                            <img className={'btn-play'} src={btnInstallImg} alt='Install' onClick={this.props.onClick}/>
                        }
                    </div>
                }
            </>
        );
    }
}
