import React from 'react';
import footerLogo from './assets/footer-logo.png';
import footerSnake from './assets/footer-snake.svg'
import './footer.scss';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'footer-container'}>
                <div className="footer-top">
                    <div className={'resizer'}>
                        <div className={'footer-snake-con'}>
                            <img src={footerSnake} width={109} height={45} alt="Snake"/>
                        </div>
                        <div className={'col-4 about'}>
                            <div>
                                <h5>About us</h5>
                                <p>We are a new gaming studio based in London, UK.
                                    Our mission is to create free and fun games for the casual-gaming community.
                                    <span>Have fun!</span>
                                </p>
                            </div>
                        </div>
                        <div className={'col-4 logo'}>
                            <img src={footerLogo} width={223} height={129} alt="Logo"/>
                        </div>
                        <div className={'col-4 terms'}>
                            <div>
                                <div className="term-1">
                                    <span>TERMS AND CONDITIONS APPLY</span>
                                </div>
                                <div className="term-2">
                                    <span onClick={()=>this.props.showModal('terms')}>Terms of Use</span>
                                    <span onClick={()=>this.props.showModal('privacy')}>Privacy Policy</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'footer-bottom'}>
                    <div className={'resizer'}>
                        <p>©2021 Snake Color Brake. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        )
    }
}