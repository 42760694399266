import React from 'react';
import BtnPlay from "../btn/btnPlay";

const InstallBox = ({ installing, btnType, playClick, onClick }) => {
  return (
      <BtnPlay
          btnType={btnType}
          onClick={onClick}
          playClick={playClick}
          installing={installing}
      />
  )
}

export default InstallBox;
