import React from 'react';
import snakes_box_bg from "../../../../assets/game-bg.png";
import subscribeBtn from "Pages/PWA/aq/games/Snake_Game_style4_push/assets/subscribe-btn.png";
import ReqTypes from "Pages/PWA/aq/games/Snake_Game_style4_push/phases/welcome/parts/reqTypes/ReqTypes";
import './subscribeBox.scss';

export default class SubscribeBox extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={'subscribe-action-box-container'}>
                <div className={'action-box'}>
                    <img src={snakes_box_bg} alt='Box' className={'action-box-bg'}/>
                    <div className={'box-body'}>
                        <div className='snake-box-title'>
                            <div className={'text'}>
                                Allow to Get FREE games and updates!
                            </div>
                        </div>
                        <div className={'install-box-btn'}>
                            <div className={'btn-play-container sdfsd'}>
                                <img className={'btn-play'} src={subscribeBtn} alt='Install'
                                     onClick={this.props.onClick}/>
                            </div>
                        </div>
                        <div className='snake-box-title'>
                            <div className={'text continue'} onClick={this.props.continueOnCLick}>
                                continue to game
                            </div>
                        </div>
                        <ReqTypes/>
                    </div>
                </div>
            </div>
        );
    }
}
